import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import logo1 from './../../images/logo/logo1.png';
import logo2 from './../../images/logo/logo2.png';
import logo3 from './../../images/logo/logo3.png';
import logo4 from './../../images/logo/logo4.png';
import logowhite from './../../images/logo-white.png';
import AspenSquare_LOGOColor from '../../images/companyLogo/AspenSquare_LOGOColor.png';
class Footer extends Component {
  render() {
    return (
      <Fragment>
        <footer className="site-footer" id="sidenav_footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                  <Footercol1 />
                </div>
                <div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.6s">
                  <Footercol2 />
                </div>
                <div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.9s">
                  <Footercol3 />
                </div>
              </div>
            </div>
          </div>
          {/*  footer bottom part */}
          <div className="footer-bottom">
            <Footerbottom />
          </div>
        </footer>
      </Fragment>
    )
  }
}
export function Footercol1({ imageLink, companyInfo }) {
  return (
    <>
      <div className="widget widget_about">
        <div className="footer-logo">
          <Link to="./"><img src={imageLink + 'company_settings/logo_small/' + companyInfo.logo_small} className="w-75 img-fluid" alt="" /><h6 className="logo-title-foot">Exceptional Excellence</h6></Link>
        </div>
        <p>Disclaimer: Aspen Square Homes is not a brokerage company and does not charge commissions.</p>
        <div className="dlab-social-icon">
          <ul>
            <li><a href={companyInfo && companyInfo.dashboard_youtube_link_2} target="_blank" className="fa fa-facebook mr-1"></a></li>
            <li><a href={companyInfo && companyInfo.dashboard_youtube_link_4} target="_blank" className="fa fa-twitter mr-1"></a></li>
            <li><a href={companyInfo && companyInfo.dashboard_youtube_link_1} target="_blank" className="fa fa-youtube mr-1"></a></li>
            <li><a href={companyInfo && companyInfo.dashboard_youtube_link_3} target="_blank" className="fa fa-instagram mr-1"></a></li>
            <li><a href={companyInfo && companyInfo.dashboard_youtube_link_5} target="_blank" className="fa fa-pinterest"></a></li>
          </ul>
        </div>
      </div>
    </>
  )
}

export function Footercol2({ companyInfo }) {

  return (
    <>
      <div className="widget">
        <h5 className="footer-title">Contact Us</h5>
        <ul className="contact-info-bx">
          <li><i className="las la-map-marker"></i><strong>Address :</strong> {companyInfo && companyInfo.address_1}</li>
          <li><i className="las la-phone-volume"></i><strong>Call : </strong>   {companyInfo && companyInfo.phone_1}</li>
          {/* <li><i className="las la-phone-square-alt"></i><strong>Board line :-</strong> 0123-4567890</li> */}
        </ul>
      </div>
    </>
  )
}

export function Footercol3({ communitiesInfo }) {
  return (
    <>
      {/* className="widget widget-logo" */}
      <div className="mb-3">
        <h5 className="footer-title mb-4">Links</h5>
        <ul className="footer-Links ">
          <li><Link to={"/"}>Home</Link></li>
        </ul>

        <ul className="footer-Links ">
          <li><Link to={"#"}>Communities</Link>
            {communitiesInfo && communitiesInfo.map((community, index) =>
              <>
                {
                  community.status == 1 && community.is_active == 1 && <li key={index}><span className="ml-2 mr-2"><i className="fa fa-angle-double-right"></i></span><Link to={community.subProject.length == 0 ? `/community/${community.project_slug}` : '#'}>{community.project_title}</Link>
                    {
                      community.subProject.length == 0 ?
                        '' :

                        <ul className="sub-menu ">
                          {
                            community.subProject && community.subProject.map((sub, index) =>
                              sub.status == 1 && sub.is_active == 1 && <li><span className="ml-4 mr-2"><i className="fa fa-angle-double-right"></i></span><a href={`/communities/${sub.project_slug}`} key={index} className="text-uppercase">{sub.project_title}</a></li>
                            )
                          }


                        </ul>
                    }
                  </li>
                }
              </>
            )}
          </li>
        </ul>

        <ul className="footer-Links ">
          <li><Link to={"#"}>Helpful Tools</Link>
            <ul>
              <li><span className="ml-2 mr-2"><i className="fa fa-angle-double-right"></i></span><Link to="/gallery">Gallery</Link></li>
              <li><span className="ml-2 mr-2"><i className="fa fa-angle-double-right"></i></span><Link to="/articles">Articles</Link></li>
            </ul>
          </li>
        </ul>



      </div>
    </>
  )
}

export function Footerbottom({ companyInfo }) {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 text-md-left text-center"> <span>Copyright © {companyInfo.copy_right_year} {companyInfo.copy_right_info_name}</span> </div>
          <div className="col-md-6 col-sm-12 text-md-right text-center">
            <div className="widget-link ">
              <ul>
                <li><Link to={"/about-us"}> About</Link></li>
                <li><Link to={"/contact-us"}> Contact Us</Link></li>
                <li><Link to={"/privacy-policy"}> Privacy Policy</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer;