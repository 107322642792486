import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
//images

import { ExhibitionSection } from '../CompanyExhibition';
import './portfolio.css';
import { Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import data from '../../../staticData/staticData.js';
import { cardButton, cardButtonHover } from '../../CommonStyle/CommonStyle';
import './portfolio.css'
import { communitiesDetails, communitiesFloorPlan, moveInFloorPlan, upComingFloorPlan } from '../../../Api/Actions/coummnities';
import { floorIcon } from '../../../Api/Actions/floorplan';
import Fade from 'react-reveal/Fade';
import moment from 'moment';
import { LoadCanvasTemplate, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';
import { contactUS } from '../../../Api/Actions/sendMail';
import { companyInfo } from '../../../Api/Actions/companyInfo';
import { Helmet } from 'react-helmet';


//Light Gallery on icon click 
/* const Iconimage = props => {
  const { openLightbox } = useLightbox()
	
  return (
    
  )
} */

const GalleryBox = ({ imageBlog }, props) => {
  const { openLightbox } = useLightbox()
  return (

    <div className="dlab-media dlab-img-overlay1 overlay-primary" >
      <img src={imageBlog} alt="" />
      <div className="overlay-bx">
        <div className="overlay-icon">
          <a href="https://www.youtube.com/watch?v=Dj6CKxQue7U" className="popup-youtube">
            <i className="fa fa-play icon-bx-xs"></i>
          </a>
          <a onClick={() => openLightbox(props.imageToOpen)} className=" " >
            <i className="fa fa-search icon-bx-xs"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

class Portfolio1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleSwitch: false,
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      loading: true,
      dataInfo: [],
      isHover: false,
      contact: [],
      success_message: '',
      error_message: '',
      submit_btn: false,

    };
    // this.tog_scroll = this.tog_scroll.bind(this);
  }
  componentDidMount() {
    new WOW.WOW().init();
    this.dataFetch();
    let url = window.location.href.split('/').pop();
    this.fetchCommunitiesInfo(url);
    this.fetchCommunitiesFloorPlan(url);
    this.fetchFloorPlanIcon();
    this.fetchMoveInFloorPlan(url);
    this.fetchUpComingFloorPlan(url)
    this.fetchCompanyInfo();
    loadCaptchaEnginge(4);
  }

  dataFetch = () => {
    this.setState({
      dataInfo: data
    })
  }
  fetchFloorPlanIcon = () => {
    floorIcon().then(response => {
      this.setState({
        floorPlanIcon: response.data,
      })
    })
  }
  fetchCommunitiesInfo = (slug) => {
    communitiesDetails(slug).then(response => {
      this.setState({
        communitiesDetails: response.data[0],
      })
    })
  }
  fetchCommunitiesFloorPlan = (slug) => {
    communitiesFloorPlan(slug).then(response => {
      this.setState({
        communitiesFloorPlan: response.data,
      })
    })
      .catch(err => console.log(err))
  }
  fetchMoveInFloorPlan = (slug) => {
    moveInFloorPlan(slug).then(response => {
      this.setState({
        moveInFloorPlan: response.data,
      })
    })
  }
  fetchUpComingFloorPlan = (slug) => {
    upComingFloorPlan(slug).then(response => {
      this.setState({
        upComingFloorPlan: response.data,
      })
    })
  }

  handleMouseEnter = () => {
    this.setState({
      isHover: true,
    })
  };
  handleMouseLeave = () => {
    this.setState({
      isHover: false,
    })
  };
  //
  openModal2 = (data) => {
    this.setState({ isOpen2: true })
    this.state.success_message = data;

  };
  closeModal2 = () => {
    this.setState({ isOpen2: false });
    this.state.success_message = "";
    // this.props.history.push('/');
  };

  openModal3 = (data) => {
    this.setState({ isOpen3: true });
    this.state.error_message = data;

  };
  closeModal3 = () => {
    this.setState({ isOpen3: false });
    this.state.error_message = "";
  };


  fetchCompanyInfo = () => {
    companyInfo().then(response => {
      this.setState({
        companyInfo: response.data,
      })
    })
  }
  handleMouseEnter = () => {
    this.setState({
      isHover: true,
    })
  };
  handleMouseLeave = () => {
    this.setState({
      isHover: false,
    })
  };


  autoSearchInputChange = (event) => {
    event.preventDefault();
    const { target: { name, value } } = event;
    this.updateContactObject(name, value);
  }

  updateContactObject = (name, value) => {
    let contactCopy = Object.assign({}, this.state.contact);
    contactCopy[name] = value;
    this.setState({
      contact: contactCopy
    });
  }

  reset = () => {
    document.getElementById("user_captcha_input").value = "";
    document.getElementById("name").value = "";
    document.getElementById("mail_body").value = "";
    document.getElementById("phone").value = "";
    document.getElementById("mail_from").value = "";
  }
  onHandleSubmit = (e) => {
    e.preventDefault();
    const { name, mail_body, mail_from, phone, user_captcha_input } = this.state.contact;
    const data = {
      name: name,
      mail_body: mail_body === undefined ? '' : mail_body,
      mail_from: mail_from === undefined ? '' : mail_from,
      phone: phone,
    }

    if (validateCaptcha(user_captcha_input) === true) {
      this.setState({
        submit_btn: true
      })
      loadCaptchaEnginge(4);
      contactUS(data)
        .then((response) => {
          if (response.success === true) {
            this.openModal2(response.data);
            this.setState({
              submit_btn: false
            })
            this.reset();
          }
          if (response.success === false) {
            this.openModal3(response.data);
            this.setState({
              submit_btn: false
            })
            this.reset();
          }
        }
        ).catch((err) => {
          console.log(err)
        })
    } else {
      let user_captcha_input = "Captcha Not Matched";
      this.openModal3(user_captcha_input);
      document.getElementById("user_captcha_input").value = "";
    }


  }
  //
  render() {
    const imageLink = process.env.REACT_APP_IMAGE_URL;
    const { isHover } = this.state;

    let communitiesDetails = [];
    let communitiesFloorPlan = [];
    let floorPlanIcon = [];
    let twoStoriesData = [];
    let threeStoriesData = [];
    let fourStoriesData = [];
    let brochure = [];
    let quickMoveHomes = [];
    let moveInHomes = [];
    let upComingHomes = {};
    let title = "";

    if (this.state.hasOwnProperty("communitiesDetails")) {
      communitiesDetails = this.state.communitiesDetails
      title = communitiesDetails.project_title.split(' ').slice(0, 2).join('');
    }
    if (this.state.hasOwnProperty("communitiesFloorPlan")) {
      communitiesFloorPlan = this.state.communitiesFloorPlan
      twoStoriesData = communitiesFloorPlan && communitiesFloorPlan.filter(f => f.stories_qty === 2).map(f => f)
      threeStoriesData = communitiesFloorPlan && communitiesFloorPlan.filter(f => f.stories_qty === 3).map(f => f)
      fourStoriesData = communitiesFloorPlan && communitiesFloorPlan.filter(f => f.stories_qty === 4).map(f => f)
    }
    if (this.state.hasOwnProperty('floorPlanIcon')) {
      floorPlanIcon = this.state.floorPlanIcon;
    }
    if (this.state.hasOwnProperty('moveInFloorPlan')) {
      moveInHomes = this.state.moveInFloorPlan;
    }
    if (this.state.hasOwnProperty('upComingFloorPlan')) {
      upComingHomes = this.state.upComingFloorPlan;
    }
    console.log('upComingHomesmonthArray', upComingHomes.monthArray)
    brochure = communitiesDetails.feature_image && communitiesDetails.feature_image.filter(imgInfo => imgInfo.is_brochure == 1 && imgInfo.is_active == 1).map((imgInfo, index) => imgInfo);
    quickMoveHomes = communitiesFloorPlan && communitiesFloorPlan.filter(f => f.is_quick_move_in_homes == 1).map((f, index) => f);

    let detectExtension = /[^.]+$/.exec(communitiesDetails.site_map_image)
    let url = window.location.href.split('/').pop();
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    console.log(communitiesDetails.project_details_description);
    var strippedHtml = communitiesDetails?.project_details_description?.replace(/<[^>]+>/g, '').slice(0, 300);
    return (
      <Fragment>
        <Helmet>
          <title>{capitalizeFirstLetter(url)} -Aspen Square Homes</title>
          <meta name="description" content={strippedHtml} />
          <meta property="og:title" content={`${capitalizeFirstLetter(url)} -Aspen Square Homes`} />
          <meta property="og:type" content="aspensquarehomes" />
          <meta property="og:description" content={strippedHtml} />
          <meta property="og:url" content={`https://aspensquarehomes.com/communities/${url}`} />
          <meta property="og:image" content={`${imageLink + 'company_settings/aboutus/sub/project_cover_image_1/' + communitiesDetails.project_cover_image_1}`} />
        </Helmet>
        <Header />
        <div className="page-content bg-white">
          {/*  banner  */}
          <PageTitle motherMenu={communitiesDetails && communitiesDetails.project_title} activeMenu={communitiesDetails && communitiesDetails.project_title} breadcrumbImage={imageLink + 'company_settings/aboutus/sub/project_cover_image_1/' + communitiesDetails.project_cover_image_1} />
          {/*  Section-1 Start  */}
          <section className="content-inner" data-content={title && title}>
            <div className="container">
              <div className="d-flex flex-column justify-content-center wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.2s">
                <h2 className="double-rules">{communitiesDetails && communitiesDetails.project_name}</h2>
              </div>
            </div>
            {
              communitiesDetails.vedio_link == null || communitiesDetails.vedio_link === "" ?
                "" :
                <section className="content-inner project-details" style={{ backgroundColor: '#569539' }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <Fade bottom>
                          <iframe width="100%" height="550px" style={{ border: '10px solid #fff', boxShadow: '0px 0px 10px 10px #8EC64C' }} src={communitiesDetails.vedio_link} title="2 STORIED ANIMATION" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </section>
            }

            <div className="container">
              <section className="content-inner exhibition-bx">
                <ExhibitionSection communitiesDetails={communitiesDetails} />
              </section>
            </div>


          </section>
          {
            communitiesFloorPlan.length === 0 && twoStoriesData.length === 0 && threeStoriesData.length === 0 && fourStoriesData.length === 0 ? '' :
              <section className="content-inner" style={{ backgroundColor: 'rgba(0, 0, 0, 0.20)' }}>
                <div className="container">
                  <div className="d-flex flex-column justify-content-center">
                    <h2 className="double-rules">FLOOR PLANS</h2>
                    <p className="text-center">
                      Our Townhomes are between 2000 sq. foot. to 2500 sq. foot. offering all the amenities of a single-family home but at a 30% less cost. Our strategic locations and state-of-the-art designs and construction technologies make our townhomes a great living place to raise your children. Or you can invest here to see the value appreciated handsomely in the shortest possible time.
                    </p>
                  </div>

                  <Tab.Container id="left-tabs-example" defaultActiveKey="all">
                    <Row className="d-flex justify-content-center">
                      <Col sm={12} md={4}>
                      </Col>
                      <Col sm={12} md={4}>


                        <>
                          <Nav variant="pills" className="flex-row justify-content-center">
                            <Nav.Item>
                              {communitiesFloorPlan.length === 0 ? '' :
                                <Nav.Link eventKey="all" href="#">
                                  All
                                </Nav.Link>
                              }

                            </Nav.Item>
                            <Nav.Item>
                              {
                                twoStoriesData.length === 0 ? '' :
                                  <Nav.Link eventKey="second" href="#">
                                    2 Stories
                                  </Nav.Link>
                              }

                            </Nav.Item>
                            <Nav.Item>
                              {
                                threeStoriesData.length === 0 ? '' :
                                  <Nav.Link eventKey="third" href="#">
                                    3 Stories
                                  </Nav.Link>
                              }

                            </Nav.Item>
                            <Nav.Item>
                              {
                                fourStoriesData.length === 0 ? '' :
                                  <Nav.Link eventKey="fourth" href="#">
                                    4 Stories
                                  </Nav.Link>
                              }

                            </Nav.Item>
                          </Nav>
                        </>
                      </Col>
                      <Col sm={12} md={4}>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="all">
                            <div className="row justify-content-md-center">
                              {
                                communitiesFloorPlan && communitiesFloorPlan.map((f, index) =>
                                  <div className="col-md-6 col-lg-3 col-sm-12">
                                    <Fade bottom>
                                      <div className="card" key={index}>
                                        <div>
                                          <Link to={`/floor-plans/${f.slug}`}><h5 className="text-center p-2 mb-0 font-weight-bold text-white bg-dark text-uppercase">{f.floore_plans_names}</h5>
                                            <img src={imageLink + 'FloorPlan/' + f.feature_image[0].f_project_names_id + '/Slider/thumbnalie/' + f.feature_image[0].slider_large_image} className="card-img-top w-100" alt="..." />
                                            {/* <p className="new-ribbon mb-0"><Link to={`/floor-plans/${f.title}`} className="text-dark text-uppercase">VIRTUAL TOUR</Link></p> */}
                                          </Link>
                                        </div>
                                        <div className="card-body p-2 card-body-design-projects">
                                          <div>
                                            <h5 className="text-primary text-center"><Link to={`/floor-plans/${f.slug}`}>{f.address}</Link> <i className="fa fa-location-arrow"></i></h5>
                                            <div className="col-md-12 d-flex justify-content-center px-0">
                                              <table className="table table-bordered">
                                                <tbody>
                                                  <tr>
                                                    {/* <td>
                                                      <img className="icon" src="https://cbjenihomes.com/wp-content/uploads/2020/06/floorplan-icon.svg" />
                                                      <span className="icon-title ml-2">Naples</span>
                                                    </td> */}
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Size").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.size_details_sq_fit} Sq. Ft.</span>
                                                    </td>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Stories").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.stories_qty} Stories</span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bedroom").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.bedroom_qty} Beds</span>
                                                    </td>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Garage").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.garage_qty} Car Garage</span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td >
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.bathroom_qty} Baths</span>
                                                    </td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                              <span className="text-dark price-title font-weight-bold">${f.price.toLocaleString()}</span>
                                              <a className="text-dark" href="#"><span className="price-title font-weight-bold">Save <i className="fa fa-heart-o"></i></span></a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-footer p-0">
                                          <button className="btn btn-primary w-100 rounded-0 py-1" style={cardButton('#000', '#fff', '#569539')} onClick={() => this.props.history.push(`/floor-plans/${f.slug}`)}
                                          >View Floor Plans</button>
                                        </div>
                                      </div>
                                    </Fade>
                                  </div>
                                )
                              }
                            </div>

                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <div className="row justify-content-md-center">
                              {
                                twoStoriesData && twoStoriesData.map((f, index) =>
                                  <div className="col-md-6 col-lg-3 col-sm-12">
                                    <Fade bottom>
                                      <div className="card" key={index}>
                                        <div>
                                          <Link to={`/floor-plans/${f.slug}`}><h5 className="text-center p-2 mb-0 font-weight-bold text-white bg-dark text-uppercase">{f.floore_plans_names}</h5>
                                            <img src={imageLink + 'FloorPlan/' + f.feature_image[0].f_project_names_id + '/Slider/thumbnalie/' + f.feature_image[0].slider_large_image} className="card-img-top w-100" alt="..." />
                                            {/* <p className="new-ribbon mb-0"><Link to={`/floor-plans/${f.title}`} className="text-dark text-uppercase">VIRTUAL TOUR</Link></p> */}
                                          </Link>
                                        </div>
                                        <div className="card-body p-2 card-body-design-projects">
                                          <div>
                                            <h5 className="text-primary text-center"><Link to={`/floor-plans/${f.slug}`}>{f.address}</Link> <i className="fa fa-location-arrow"></i></h5>
                                            <div className="col-md-12 d-flex justify-content-center px-0">
                                              <table className="table table-bordered">
                                                <tbody>
                                                  <tr>
                                                    {/* <td>
                                                      <img className="icon" src="https://cbjenihomes.com/wp-content/uploads/2020/06/floorplan-icon.svg" />
                                                      <span className="icon-title ml-2">Naples</span>
                                                    </td> */}
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Size").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.size_details_sq_fit} Sq. Ft.</span>
                                                    </td>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Stories").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.stories_qty} Stories</span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bedroom").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.bedroom_qty} Beds</span>
                                                    </td>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Garage").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.garage_qty} Car Garage</span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.bathroom_qty} Baths</span>
                                                    </td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                              <span className="text-dark price-title font-weight-bold">${f.price}</span>
                                              <a className="text-dark" href="#"><span className="price-title font-weight-bold">Save <i className="fa fa-heart-o"></i></span></a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-footer p-0">
                                          <button className="btn btn-primary w-100 rounded-0 py-1" style={cardButton('#000', '#fff', '#569539')} onClick={() => this.props.history.push(`/floor-plans/${f.slug}`)}
                                          >View Floor Plans</button>
                                        </div>
                                      </div>
                                    </Fade>
                                  </div>
                                )
                              }
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <div className="row justify-content-md-center">
                              {
                                threeStoriesData && threeStoriesData.map((f, index) =>
                                  <div className="col-md-6 col-lg-3 col-sm-12">
                                    <Fade bottom>
                                      <div className="card" key={index}>
                                        <div>
                                          <Link to={`/floor-plans/${f.slug}`}><h5 className="text-center p-2 mb-0 font-weight-bold text-white bg-dark text-uppercase">{f.floore_plans_names}</h5>
                                            <img src={imageLink + 'FloorPlan/' + f.feature_image[0].f_project_names_id + '/Slider/thumbnalie/' + f.feature_image[0].slider_large_image} className="card-img-top w-100" alt="..." />
                                            {/* <p className="new-ribbon mb-0"><Link to={`/floor-plans/${f.title}`} className="text-dark text-uppercase">VIRTUAL TOUR</Link></p> */}
                                          </Link>
                                        </div>
                                        <div className="card-body p-2 card-body-design-projects">
                                          <div>
                                            <h5 className="text-primary text-center"><Link to={`/floor-plans/${f.slug}`}>{f.address}</Link> <i className="fa fa-location-arrow"></i></h5>
                                            <div className="col-md-12 d-flex justify-content-center px-0">
                                              <table className="table table-bordered">
                                                <tbody>
                                                  <tr>
                                                    {/* <td>
                                                      <img className="icon" src="https://cbjenihomes.com/wp-content/uploads/2020/06/floorplan-icon.svg" alt="icon" />
                                                      <span className="icon-title ml-2">Naples</span>
                                                    </td> */}
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Size").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.size_details_sq_fit} Sq. Ft.</span>
                                                    </td>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Stories").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.stories_qty} Stories</span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bedroom").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.bedroom_qty} Beds</span>
                                                    </td>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Garage").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.garage_qty} Car Garage</span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.bathroom_qty} Baths</span>
                                                    </td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                              <span className="text-dark price-title font-weight-bold">${f.price}</span>
                                              <a className="text-dark" href="#"><span className="price-title font-weight-bold">Save <i className="fa fa-heart-o"></i></span></a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-footer p-0">
                                          <button className="btn btn-primary w-100 rounded-0 py-1" style={cardButton('#000', '#fff', '#569539')} onClick={() => this.props.history.push(`/floor-plans/${f.slug}`)}
                                          >View Floor Plans</button>
                                        </div>
                                      </div>
                                    </Fade>
                                  </div>
                                )
                              }
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth">
                            <div className="row justify-content-md-center">
                              {
                                fourStoriesData && fourStoriesData.map((f, index) =>
                                  <div className="col-md-6 col-lg-3 col-sm-12">
                                    <Fade bottom>
                                      <div className="card" key={index}>
                                        <div>
                                          <Link to={`/floor-plans/${f.slug}`}><h5 className="text-center p-2 mb-0 font-weight-bold text-white bg-dark text-uppercase">{f.floore_plans_names}</h5>
                                            <img src={imageLink + 'FloorPlan/' + f.feature_image[0].f_project_names_id + '/Slider/thumbnalie/' + f.feature_image[0].slider_large_image} className="card-img-top w-100" alt="..." />
                                            {/* <p className="new-ribbon mb-0"><Link to={`/floor-plans/${f.title}`} className="text-dark text-uppercase">VIRTUAL TOUR</Link></p> */}
                                          </Link>
                                        </div>
                                        <div className="card-body p-2 card-body-design-projects">
                                          <div>
                                            <h5 className="text-primary text-center"><Link to={`/floor-plans/${f.slug}`}>{f.address}</Link> <i className="fa fa-location-arrow"></i></h5>
                                            <div className="col-md-12 d-flex justify-content-center px-0">
                                              <table className="table table-bordered">
                                                <tbody>
                                                  <tr>
                                                    {/* <td>
                                                      <img className="icon" src="https://cbjenihomes.com/wp-content/uploads/2020/06/floorplan-icon.svg" alt="icon" />
                                                      <span className="icon-title ml-2">Naples</span>
                                                    </td> */}
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Size").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.size_details_sq_fit} Sq. Ft.</span>
                                                    </td>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Stories").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.stories_qty} Stories</span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bedroom").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.bedroom_qty} Beds</span>
                                                    </td>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Garage").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.garage_qty} Car Garage</span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                        <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title ml-2">{f.bathroom_qty} Baths</span>
                                                    </td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                              <span className="text-dark price-title font-weight-bold">${f.price}</span>
                                              <a className="text-dark" href="#"><span className="price-title font-weight-bold">Save <i className="fa fa-heart-o"></i></span></a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-footer p-0">
                                          <button className="btn btn-primary w-100 rounded-0 py-1" style={cardButton('#000', '#fff', '#569539')} onClick={() => this.props.history.push(`/floor-plans/${f.slug}`)}
                                          >View Floor Plans</button>
                                        </div>
                                      </div>
                                    </Fade>
                                  </div>
                                )
                              }
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </section>

          }

          {
            moveInHomes.length === 0 ? '' :
              <section className="content-inner" style={{ backgroundColor: '#89afc9' }}>
                <div className="container">
                  <div className="d-flex flex-column justify-content-center">
                    <h2 className="double-rules text-white">MOVE-IN READY HOMES</h2>
                  </div>
                  <div className="row justify-content-md-center">
                    {
                      moveInHomes && moveInHomes.map((f, index) =>
                        <div className="col-md-6 col-lg-4 col-sm-12 ">
                          <Fade bottom>
                            <div className="card setHeight" key={index}>
                              <div>
                                <Link to={`/move-in-homes/${f.slug}`}><h5 className="text-center p-2 mb-0 font-weight-bold text-white bg-dark text-uppercase">
                                  <span>
                                    Upcoming {moment(f.quick_move_in_homes_ready_date).format("MMMM Do, YYYY")} <br></br>
                                  </span>
                                  {f.floore_plans_names} </h5>
                                  <img src={imageLink + 'MoveIn/' + f.feature_image[0].f_floor_plane_id + '/Slider/thumbnalie/' + f.feature_image[0].slider_large_image} className="card-img-top w-100" alt="..." />
                                  {/* <p className="new-ribbon mb-0"><Link to={`/floor-plans/${f.title}`} className="text-dark text-uppercase">VIRTUAL TOUR</Link></p> */}
                                </Link>
                              </div>
                              <div className="card-body p-2 card-body-design-projects">
                                <div>
                                  <h5 className="text-primary text-center"><Link to={`/floor-plans/${f.slug}`}>{f.address}</Link> <i className="fa fa-location-arrow"></i></h5>
                                  <div className="col-md-12 d-flex justify-content-center px-0">
                                    <table className="table table-bordered">
                                      <tbody>
                                        <tr>
                                          {/* <td>
                                        <img className="icon" src="https://cbjenihomes.com/wp-content/uploads/2020/06/floorplan-icon.svg" />
                                        <span className="icon-title ml-2">Naples</span>
                                      </td> */}
                                          <td >
                                            {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Size").map(floor =>
                                              <img className="icon" style={{ marginLeft: "8.5px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                            )
                                            }
                                            <span className="icon-title" style={{ marginLeft: "18px" }}>{f.size_details_sq_fit} Sq. Ft.</span>
                                          </td>
                                          <td>
                                            <div className='ml-2'>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Stories").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-3">{f.stories_qty} Stories</span>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bedroom").map(floor =>
                                              <img className="icon" style={{ marginLeft: "8.5px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                            )
                                            }
                                            <span className="icon-title" style={{ marginLeft: "18px" }}>{f.bedroom_qty} Beds</span>
                                          </td>
                                          <td>
                                            <div className='ml-2'>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Garage").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-3">{f.garage_qty} Car Garage</span>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                              <img className="icon" style={{ marginLeft: "8.5px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                            )
                                            }
                                            <span className="icon-title" style={{ marginLeft: "18px" }}>{f.bathroom_qty} Baths</span>
                                          </td>
                                          <td>
                                            <div className='row pl-1'>
                                              <div className='col-3 p-0 m-0' >
                                                {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                  <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                )
                                                }
                                              </div>
                                              <div className='col-9 p-0 pr-3' >
                                                <span className="icon-title">{f.kitchen}</span>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className='row pl-1'>
                                              <div className='col-3 p-0 m-0' >
                                                {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                  <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                )
                                                }
                                              </div>
                                              <div className='col-9 px-1 ' >
                                                <span className="icon-title pr-2">{f.utility_cabinet}</span>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div className='row pl-1'>
                                              <div className='col-3 p-0 m-0' >
                                                {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                  <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                )
                                                }
                                              </div>
                                              <div className='col-9 p-0 pr-3' >
                                                <span className="icon-title">{f.kitchen_cab}</span>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className='row pl-1'>
                                              <div className='col-3 p-0 m-0' >
                                                {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                  <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                )
                                                }
                                              </div>
                                              <div className='col-9 px-1 ' >
                                                <span className="icon-title pr-2">{f.countertop_upgrade}</span>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div className='ml-2'>
                                              {
                                                f.walk_in_shower === "yes" ?
                                                  <>
                                                    {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                      <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                    )
                                                    }
                                                    <span className="icon-title ml-3">{f.walk_in_shower === "yes" ? "Walk in shower" : ""} </span>
                                                  </> : ""}
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center mt-2 card_bottom">
                                    <span className="text-dark price-title font-weight-bold">${f.price.toLocaleString()}</span>
                                    <a className="text-dark" href="#"><span className="price-title font-weight-bold">Save <i className="fa fa-heart-o"></i></span></a>
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer p-0">
                                <button className="btn btn-primary w-100 rounded-0 py-1" style={cardButton('#000', '#fff', '#569539')} onClick={() => this.props.history.push(`/move-in-homes/${f.slug}`)}
                                >View Floor Plans</button>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      )
                    }
                  </div>
                </div>
              </section>
          }

          {
            upComingHomes.monthArray === undefined || upComingHomes?.monthArray?.length === 0 ? '' :
              <section className="content-inner" style={{ backgroundColor: 'rgba(0, 0, 0, 0.20)' }}>
                <div className="container">
                  <div className="d-flex flex-column justify-content-center">
                    <h2 className="double-rules">Upcoming Homes</h2>
                    {/* <p className="text-center">
                      Our Townhomes are between 2000 sq. foot. to 2500 sq. foot. offering all the amenities of a single-family home but at a 30% less cost. Our strategic locations and state-of-the-art designs and construction technologies make our townhomes a great living place to raise your children. Or you can invest here to see the value appreciated handsomely in the shortest possible time.
                    </p> */}
                  </div>

                  <Tab.Container id="left-tabs-example" defaultActiveKey="all">
                    <Row className="d-flex justify-content-center">
                      <Col sm={12} md={4}>
                      </Col>
                      <Col sm={12} md={4}>
                        <>
                          <Nav variant="pills" className="flex-row justify-content-center">
                            <Nav.Item>
                              {communitiesFloorPlan.length === 0 ? '' :
                                <Nav.Link eventKey="all" href="#">
                                  All
                                </Nav.Link>
                              }
                            </Nav.Item>
                            {
                              upComingHomes?.monthArray.map((data, index) => (
                                <Nav.Item>
                                  {
                                    <Nav.Link eventKey={data} href="#">
                                      {data}
                                    </Nav.Link>
                                  }

                                </Nav.Item>
                              ))
                            }
                          </Nav>
                        </>
                      </Col>
                      <Col sm={12} md={4}>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="all">
                            <div className="row justify-content-md-center">
                              {
                                upComingHomes && upComingHomes?.dataList?.map((f, index) =>
                                  <div className="col-md-6 col-lg-4 col-sm-12 ">
                                    <Fade bottom>
                                      <div className="card setHeight " key={index}>
                                        <div>
                                          <Link to={`/move-in-homes/${f.slug}`}><h5 className="text-center p-2 mb-0 font-weight-bold text-white bg-dark text-uppercase">
                                            <span>
                                              Upcoming {moment(f.quick_move_in_homes_ready_date).format("MMMM Do, YYYY")} <br></br>
                                            </span>
                                            {f.floore_plans_names} </h5>
                                            <img src={imageLink + 'MoveIn/' + f.feature_image[0].f_floor_plane_id + '/Slider/thumbnalie/' + f.feature_image[0].slider_large_image} className="card-img-top w-100" alt="..." />
                                            {/* <p className="new-ribbon mb-0"><Link to={`/floor-plans/${f.title}`} className="text-dark text-uppercase">VIRTUAL TOUR</Link></p> */}
                                          </Link>
                                        </div>
                                        <div className="card-body p-2 card-body-design-projects">
                                          <div>
                                            <h5 className="text-primary text-center"><Link to={`/floor-plans/${f.slug}`}>{f.address}</Link> <i className="fa fa-location-arrow"></i></h5>
                                            <div className="col-md-12 d-flex justify-content-center px-0">
                                              <table className="table table-bordered">
                                                <tbody>
                                                  <tr>
                                                    {/* <td>
                                                      <img className="icon" src="https://cbjenihomes.com/wp-content/uploads/2020/06/floorplan-icon.svg" />
                                                      <span className="icon-title ml-2">Naples</span>
                                                    </td> */}
                                                    <td >
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Size").map(floor =>
                                                        <img className="icon" style={{ marginLeft: "8.5px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title" style={{ marginLeft: "18px" }}>{f.size_details_sq_fit} Sq. Ft.</span>
                                                    </td>
                                                    <td>
                                                      <div className='ml-2'>
                                                        {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Stories").map(floor =>
                                                          <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                        )
                                                        }
                                                        <span className="icon-title ml-3">{f.stories_qty} Stories</span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bedroom").map(floor =>
                                                        <img className="icon" style={{ marginLeft: "8.5px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title" style={{ marginLeft: "18px" }}>{f.bedroom_qty} Beds</span>
                                                    </td>
                                                    <td>
                                                      <div className='ml-2'>
                                                        {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Garage").map(floor =>
                                                          <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                        )
                                                        }
                                                        <span className="icon-title ml-3">{f.garage_qty} Car Garage</span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                        <img className="icon" style={{ marginLeft: "8.5px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                      )
                                                      }
                                                      <span className="icon-title" style={{ marginLeft: "18px" }}>{f.bathroom_qty} Baths</span>
                                                    </td>
                                                    <td>
                                                      <div className='row pl-1'>
                                                        <div className='col-3 p-0 m-0' >
                                                          {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                            <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                          )
                                                          }
                                                        </div>
                                                        <div className='col-9 p-0 pr-3' >
                                                          <span className="icon-title">{f.kitchen}</span>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <div className='row pl-1'>
                                                        <div className='col-3 p-0 m-0' >
                                                          {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                            <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                          )
                                                          }
                                                        </div>
                                                        <div className='col-9 px-1 ' >
                                                          <span className="icon-title pr-2">{f.utility_cabinet}</span>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className='row pl-1'>
                                                        <div className='col-3 p-0 m-0' >
                                                          {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                            <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                          )
                                                          }
                                                        </div>
                                                        <div className='col-9 p-0 pr-3' >
                                                          <span className="icon-title">{f.kitchen_cab}</span>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <div className='row pl-1'>
                                                        <div className='col-3 p-0 m-0' >
                                                          {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                            <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                          )
                                                          }
                                                        </div>
                                                        <div className='col-9 px-1 ' >
                                                          <span className="icon-title pr-2">{f.countertop_upgrade}</span>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className='ml-2'>
                                                        {
                                                          f.walk_in_shower === "yes" ?
                                                            <>
                                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                              )
                                                              }
                                                              <span className="icon-title ml-3">{f.walk_in_shower === "yes" ? "Walk in shower" : ""} </span>
                                                            </> : ""}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2 card_bottom">
                                              <span className="text-dark price-title font-weight-bold">${f.price.toLocaleString()}</span>
                                              <a className="text-dark" href="#"><span className="price-title font-weight-bold">Save <i className="fa fa-heart-o"></i></span></a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-footer p-0">
                                          <button className="btn btn-primary w-100 rounded-0 py-1" style={cardButton('#000', '#fff', '#569539')} onClick={() => this.props.history.push(`/move-in-homes/${f.slug}`)}
                                          >View Floor Plans</button>
                                        </div>
                                      </div>
                                    </Fade>
                                  </div>
                                )
                              }
                            </div>

                          </Tab.Pane>

                          {
                            upComingHomes?.monthArray.map((data, index) => (
                              <Tab.Pane eventKey={data}>
                                <div className="row justify-content-md-center">
                                  {
                                    upComingHomes && upComingHomes?.dataList?.map((f, index) => (
                                      f.month === data &&
                                      <div className="col-md-6 col-lg-4 col-sm-12 ">
                                        <Fade bottom>
                                          <div className="card setHeight" key={index}>
                                            <div>
                                              <Link to={`/move-in-homes/${f.slug}`}><h5 className="text-center p-2 mb-0 font-weight-bold text-white bg-dark text-uppercase">
                                                <span>
                                                  Upcoming {moment(f.quick_move_in_homes_ready_date).format("MMMM Do, YYYY")} <br></br>
                                                </span>
                                                {f.floore_plans_names} </h5>
                                                <img src={imageLink + 'MoveIn/' + f.feature_image[0].f_floor_plane_id + '/Slider/thumbnalie/' + f.feature_image[0].slider_large_image} className="card-img-top w-100" alt="..." />
                                                {/* <p className="new-ribbon mb-0"><Link to={`/floor-plans/${f.title}`} className="text-dark text-uppercase">VIRTUAL TOUR</Link></p> */}
                                              </Link>
                                            </div>
                                            <div className="card-body p-2 card-body-design-projects">
                                              <div>
                                                <h5 className="text-primary text-center"><Link to={`/floor-plans/${f.slug}`}>{f.address}</Link> <i className="fa fa-location-arrow"></i></h5>
                                                <div className="col-md-12 d-flex justify-content-center px-0">
                                                  <table className="table table-bordered">
                                                    <tbody>
                                                      <tr>
                                                        {/* <td>
                                                      <img className="icon" src="https://cbjenihomes.com/wp-content/uploads/2020/06/floorplan-icon.svg" />
                                                      <span className="icon-title ml-2">Naples</span>
                                                    </td> */}
                                                        <td >
                                                          {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Size").map(floor =>
                                                            <img className="icon" style={{ marginLeft: "8.5px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                          )
                                                          }
                                                          <span className="icon-title" style={{ marginLeft: "18px" }}>{f.size_details_sq_fit} Sq. Ft.</span>
                                                        </td>
                                                        <td>
                                                          <div className='ml-2'>
                                                            {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Stories").map(floor =>
                                                              <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                            )
                                                            }
                                                            <span className="icon-title ml-3">{f.stories_qty} Stories</span>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bedroom").map(floor =>
                                                            <img className="icon" style={{ marginLeft: "8.5px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                          )
                                                          }
                                                          <span className="icon-title" style={{ marginLeft: "18px" }}>{f.bedroom_qty} Beds</span>
                                                        </td>
                                                        <td>
                                                          <div className='ml-2'>
                                                            {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Garage").map(floor =>
                                                              <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                            )
                                                            }
                                                            <span className="icon-title ml-3">{f.garage_qty} Car Garage</span>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                            <img className="icon" style={{ marginLeft: "8.5px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                          )
                                                          }
                                                          <span className="icon-title" style={{ marginLeft: "18px" }}>{f.bathroom_qty} Baths</span>
                                                        </td>
                                                        <td>
                                                          <div className='row pl-1'>
                                                            <div className='col-3 p-0 m-0' >
                                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                                <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                              )
                                                              }
                                                            </div>
                                                            <div className='col-9 p-0 pr-3' >
                                                              <span className="icon-title">{f.kitchen}</span>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <div className='row pl-1'>
                                                            <div className='col-3 p-0 m-0' >
                                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                                <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                              )
                                                              }
                                                            </div>
                                                            <div className='col-9 px-1 ' >
                                                              <span className="icon-title pr-2">{f.utility_cabinet}</span>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='row pl-1'>
                                                            <div className='col-3 p-0 m-0' >
                                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                                <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                              )
                                                              }
                                                            </div>
                                                            <div className='col-9 p-0 pr-3' >
                                                              <span className="icon-title">{f.kitchen_cab}</span>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <div className='row pl-1'>
                                                            <div className='col-3 p-0 m-0' >
                                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                                <img className="icon" style={{ marginLeft: "20px" }} src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                              )
                                                              }
                                                            </div>
                                                            <div className='col-9 px-1 ' >
                                                              <span className="icon-title pr-2">{f.countertop_upgrade}</span>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='ml-2'>
                                                            {
                                                              f.walk_in_shower === "yes" ?
                                                                <>
                                                                  {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                                    <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                                                  )
                                                                  }
                                                                  <span className="icon-title ml-3">{f.walk_in_shower === "yes" ? "Walk in shower" : ""} </span>
                                                                </> : ""}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mt-2 card_bottom">
                                                  <span className="text-dark price-title font-weight-bold">${f.price.toLocaleString()}</span>
                                                  <a className="text-dark" href="#"><span className="price-title font-weight-bold">Save <i className="fa fa-heart-o"></i></span></a>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="card-footer p-0">
                                              <button className="btn btn-primary w-100 rounded-0 py-1" style={cardButton('#000', '#fff', '#569539')} onClick={() => this.props.history.push(`/move-in-homes/${f.slug}`)}
                                              >View Floor Plans</button>
                                            </div>
                                          </div>
                                        </Fade>
                                      </div>
                                    )
                                    )
                                  }
                                </div>

                              </Tab.Pane>
                            ))
                          }





                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                  {/* <p className="text-center">This community is sold out</p> */}

                  {/* <div className="d-flex justify-content-center text-dark" style={{fontSize:'12px'}}>
								<i className="text-center mb-1">SAVE AND COMPARE HOMES AND FLOOR PLANS WITH YOUR ASPEN SQUARE ACCOUNT</i>
							</div> */}
                  {/* <div className="d-flex justify-content-center">
								<div>
									<button className="btn btn-primary w-100 rounded-0 text-uppercase py-2">Compare Now</button>
								</div>
							</div> */}
                </div>
              </section>

          }
          {
            communitiesDetails.site_map_image == null ? '' :
              <section className="content-inner">
                <div className="container">
                  <Fade bottom>
                    <div className="d-flex flex-column justify-content-center">
                      <h2 className="double-rules">SITE MAP</h2>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="d-flex justify-content-center">
                      <div className="col-md-12">
                        {
                          detectExtension != "pdf" ?
                            <img src={imageLink + 'company_settings/aboutus/sub/site_map_image/' + communitiesDetails.site_map_image} style={{ border: '10px solid #569539', height: "650px", width: "100%" }} className=" img-fluid" />
                            :
                            <iframe
                              src={imageLink + 'company_settings/aboutus/sub/site_map_image/' + communitiesDetails.site_map_image}
                              frameBorder="0"
                              scrolling="none"
                              height="650px"
                              width="100%"
                              style={{ border: '10px solid #569539' }}
                            ></iframe>

                        }

                        {/* <img src={sudance} className="w-100 img-fluid"  /> */}
                      </div>
                    </div>
                  </Fade>
                </div>
              </section>
          }

          {
            brochure && brochure.length == 0 || brochure == null ? '' :
              <section className="content-inner" style={{ backgroundColor: 'rgba(0, 0, 0, 0.20)' }}>
                <div className="container">
                  <Fade bottom>
                    <div className="d-flex flex-column justify-content-center">
                      <h2 className="double-rules">Brochure</h2>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div>
                      <div className="row">
                        {/* <div className="col-md-2">
									</div> */}
                        <div className="col-md-12">

                          {
                            brochure && brochure.map((imgInfo, index) =>
                              <iframe
                                key={index}
                                src={imageLink + '/ProjectSlider/brochure/' + imgInfo.slider_large_image}
                                frameBorder="0"
                                scrolling="none"
                                height="750px"
                                width="100%"
                                style={{ border: '10px solid #569539' }}
                              />)
                          }

                          {/* <img src={sudance} className="w-100 img-fluid"  /> */}
                        </div>
                        {/* <div className="col-md-2">
									</div> */}
                      </div>

                    </div>
                  </Fade>
                </div>

              </section>

          }

          {
            communitiesDetails.area_map_url == null ? '' :
              <section className="content-inner">
                <div className="container">
                  <Fade bottom>
                    <div className="d-flex flex-column justify-content-center">
                      <h2 className="double-rules">Area Attraction</h2>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="banner-map">
                      <iframe src={communitiesDetails.area_map_url} style={{ border: "0", width: "100%", minHeight: "500px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </Fade>
                </div>
              </section>
          }


          <section className="content-inner">
            <div className="container">
              <div className="d-flex flex-column justify-content-center">
                <h2 className="double-rules wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">COMMUNITY SALES INFORMATION</h2>
              </div>
              <div className="row">
                <div className="col-md-4 wow fadeInLeft" data-wow-duration='2s' data-wow-delay="0.4s">
                  <div className="card">
                    {communitiesDetails.project_logo != undefined ?
                      <>
                        <img src={imageLink + 'company_settings/aboutus/sub/project_logo/' + communitiesDetails.project_logo} className="card-img-top w-100 h-100" alt="..." />
                        <h6 className="logo-title-mobile text-center mb-0 py-0 text-primary" style={{ backgroundColor: "rgba(0, 0, 0, 0.1" }}>Exceptional Excellence</h6>
                      </>
                      : ''
                    }
                    <div className="card-body rounded-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.1" }}>
                      <h5 className="card-title text-uppercase">Aspen Square</h5>
                      <hr className="text-dark border-1" />
                      <i className="card-text">Community Sales Manager</i>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s" >
                  <div className="bg-dark" style={{ height: '180px ' }}>
                    <h4 className="text-center text-white pt-2">
                      <u className="text-white">Visit Our Community Sales Office</u>
                    </h4>
                    {/* {
                                url == "aspen-square-townhomes" ? <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                                   13151 Emily Rd, Suite 201, Dallas, TX-75240
                                </p> : url == "merritt-villas-townhomes" ? <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                                    13151 Emily Rd, Suite 201, Dallas, Texas - 75240
                                </p> : url == "gilbert-villas-townhomes" ? <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                                    Gilbert Villas, 108 E Shady Grove Irving, TX 75060
                                </p> :  */}
                    <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                      {communitiesDetails.project_address
                      }
                    </p>
                    {/* } */}

                    <p className="text-center text-white">Monday – Thursday : 10AM to 6PM , Friday – Saturday : 11AM to 4PM, Sunday : Close</p>
                  </div>
                  <div className="mt-2 bg-dark" style={{ height: '150px ' }}>
                    <h4 className="text-center text-white pt-2">
                      <u className="text-white">Reach Out Now</u>
                    </h4>
                    <div className="d-flex d- justify-content-center">
                      <Link to="/contact-us"><button className="btn btn-sm btn-outline-light rounded-0 mx-1"><i className="fa fa-calendar"></i> Book Now</button></Link>
                      <button className="btn btn-sm btn-outline-light rounded-0 mx-1"><i className="fa fa-phone"></i> {communitiesDetails.project_phone_no}</button>
                      <Link to="/contact-us"><button className="btn btn-sm btn-outline-light rounded-0 mx-1"><i className="fa fa-envelope"></i> Contact Us</button></Link>
                    </div>
                  </div>
                  {/*</div>
                <div className="col-lg-12 m-b30 mt-2 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.6s">
                  <div className="section-head">
                    <form className="contact-box dzForm p-a30 border-1" action="script/contact.php">
                      <h3 className="title-box">REQUEST MORE INFORMATION</h3>
                      <div className="dzFormMsg m-b20"></div>
                      <input type="hidden" value="Contact" name="dzToDo" />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="input-group">
                              <input name="dzFirstName" type="text" required="" className="form-control" placeholder="First Name*" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="input-group">
                              <input name="dzLastName" type="text" required="" className="form-control" placeholder="Last Name*" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <div className="input-group">
                              <input name="dzEmail" type="email" className="form-control" required="" placeholder="Enter Address*" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="input-group">
                              <input name="dzOther[Phone]" type="text" required="" className="form-control" placeholder="Phone Number*" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="input-group">
                              <input name="dzZipCode" type="text" className="form-control" required="" placeholder="Zip Code*" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <div className="input-group">
                              <textarea name="dzMessage" rows="4" className="form-control" required="" placeholder="Question"></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group form-recaptcha">
                            <div className="input-group">
                              <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                              <input className="form-control d-none" style={{ display: "none" }} data-recaptcha="true" required data-error="Please complete the Captcha" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()}>Submit</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <Link to={"/contact-us-1"} className="btn btn-primary m-r10 m-b10">Contact us</Link>
						<Link to={"/portfolio-1"} className="btn btn-primary m-b10">View Portfolio</Link> */}

                </div>
              </div>

            </div>
          </section>
          <section className="content-inner contact-box">
            <div className="container">
              <div className="section-head text-center">
                <span className="wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">Get in touch with us</span>
                <h2 className="title wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">Contact us</h2>
              </div>
              <div className="dzFormMsg"></div>
              <form className="dzForm" onSubmit={this.onHandleSubmit}>
                {/* <input type="hidden" value="Contact" name="dzToDo" /> */}
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <div className="form-group">
                      <input name="name" id="name" type="text" required className="form-control" autoComplete="off" placeholder="Enter your name" autoCom onChange={(e) => this.autoSearchInputChange(e)} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <div className="form-group">
                      <input name="mail_from" type="email" id="mail_from" className="form-control" autoComplete="off" required placeholder="Your Email Address" onChange={(e) => this.autoSearchInputChange(e)} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <div className="form-group">
                      <input name="phone" type="text" id="phone" required className="form-control" autoComplete="off" placeholder="Phone" onChange={(e) => this.autoSearchInputChange(e)} />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <div className="form-group">
                      <textarea name="mail_body" id="mail_body" rows="4" className="form-control" autoComplete="off" required placeholder="Your message here" onChange={(e) => this.autoSearchInputChange(e)}></textarea>
                    </div>
                  </div>
                  {/* <div className="col-md-12 col-sm-12">
										<div className="form-group">
											<div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
											<input className="form-control d-none" style={{display:"none"}} data-recaptcha="true" required data-error="Please complete the Captcha" />
										</div>
									</div> */}
                  <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <div>
                      <div className="d-flex justify-content-center captcha-design ">
                        <LoadCanvasTemplate />
                      </div>
                      <div className="form-group">
                        <input placeholder="Enter Captcha"
                          id="user_captcha_input"
                          name="user_captcha_input"
                          type="text"
                          className="form-control"
                          onChange={(e) => this.autoSearchInputChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 text-center wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <button type="submit" className="btn btn-primary btn-lg" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()} disabled={this.state.submit_btn == true}>Send Message</button>
                  </div>
                </div>
              </form>

              <Modal show={this.state.isOpen2} size="md" animation={true} centered>
                <Modal.Body>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-center">
                        <i className="fa fa-check text-light fa-2x icon-style"></i>
                      </div>
                      <div className="mt-3">
                        <h4 className="text-center text-uppercase text-uppercase">{this.state.success_message}</h4>
                        {/* <p className="text-center" style={{ fontSize: '10px' }}>Thank You ❤️. One of our responsible person contact with you asap!</p> */}
                      </div>
                      <div className="d-flex justify-content-center">
                        <button className="btn btn-primary" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()} onClick={this.closeModal2}>Ok</button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>

              </Modal>

              <Modal show={this.state.isOpen3} size="md" animation={true} centered>
                <Modal.Body>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-center">
                        <i className="fa fa-times text-light fa-2x icon-style2"></i>
                      </div>
                      <div className="mt-3">
                        <h4 className="text-center text-uppercase">{this.state.error_message}</h4>
                        {/* <p className="text-center" style={{ fontSize: '10px' }}>This lot is already booked!</p> */}
                      </div>
                      <div className="d-flex justify-content-center">
                        <button className="btn btn-primary" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()} onClick={this.closeModal3}>Ok</button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>

              </Modal>
            </div>
          </section>
          {/*  Section-1 End  */}
          <div>
            <img src={imageLink + 'company_settings/aboutus/sub/project_cover_image_2/' + communitiesDetails.project_cover_image_2} className="bg-view" alt="" />
          </div>
        </div >
        <Footer2 />
      </Fragment >
    )
  }
}
export { GalleryBox };
export default Portfolio1;