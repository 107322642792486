import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
import breadcrumbImage from '../../../images/banner.png';

//images/

import bgimg from './../../../images/bg-view.png';
import { cardButton, cardButtonHover } from '../../CommonStyle/CommonStyle';
import { companyInfo } from '../../../Api/Actions/companyInfo';
import { contactUS } from '../../../Api/Actions/sendMail';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

class ContactUs2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
      contact: [],
      isOpen2: false,
      isOpen3: false,
      success_message: '',
      error_message: '',
      submit_btn: false,



    }
  }
  componentDidMount() {
    new WOW.WOW().init();
    this.fetchCompanyInfo();
    loadCaptchaEnginge(4);

  }


  openModal2 = (data) => {
    this.setState({ isOpen2: true })
    this.state.success_message = data;

  };
  closeModal2 = () => {
    this.setState({ isOpen2: false });
    this.state.success_message = "";
    // this.props.history.push('/');
  };

  openModal3 = (data) => {
    this.setState({ isOpen3: true });
    this.state.error_message = data;

  };
  closeModal3 = () => {
    this.setState({ isOpen3: false });
    this.state.error_message = "";
  };


  fetchCompanyInfo = () => {
    companyInfo().then(response => {
      this.setState({
        companyInfo: response.data,
      })
    })
  }
  handleMouseEnter = () => {
    this.setState({
      isHover: true,
    })
  };
  handleMouseLeave = () => {
    this.setState({
      isHover: false,
    })
  };


  autoSearchInputChange = (event) => {
    event.preventDefault();
    const { target: { name, value } } = event;
    this.updateContactObject(name, value);
  }

  updateContactObject = (name, value) => {
    let contactCopy = Object.assign({}, this.state.contact);
    contactCopy[name] = value;
    this.setState({
      contact: contactCopy
    });
  }

  reset = () => {
    document.getElementById("user_captcha_input").value = "";
    document.getElementById("name").value = "";
    document.getElementById("mail_body").value = "";
    document.getElementById("phone").value = "";
    document.getElementById("mail_from").value = "";
  }
  onHandleSubmit = (e) => {
    e.preventDefault();
    const { name, mail_body, mail_from, phone, user_captcha_input } = this.state.contact;
    const data = {
      name: name,
      mail_body: mail_body == undefined ? '' : mail_body,
      mail_from: mail_from == undefined ? '' : mail_from,
      phone: phone,
    }

    if (validateCaptcha(user_captcha_input) == true) {
      this.setState({
        submit_btn: true
      })
      loadCaptchaEnginge(4);
      contactUS(data)
        .then((response) => {
          if (response.success == true) {
            this.openModal2(response.data);
            this.setState({
              submit_btn: false
            })
            this.reset();
          }
          if (response.success == false) {
            this.openModal3(response.data);
            this.setState({
              submit_btn: false
            })
            this.reset();
          }
        }
        ).catch((err) => {
          console.log(err)
        })
    } else {
      let user_captcha_input = "Captcha Not Matched";
      this.openModal3(user_captcha_input);
      document.getElementById("user_captcha_input").value = "";
    }


  }
  render() {
    const { isHover } = this.state;
    const imageLink = process.env.REACT_APP_IMAGE_URL;
    let companyInfo = [];
    if (this.state.hasOwnProperty('companyInfo')) {
      companyInfo = this.state.companyInfo[0];
    }
    return (
      <Fragment>
        <Helmet>
          <title>Contact Us -Aspen Square Homes</title>
          <meta name="description" content="Our mission is to enrich our customers' lives with an exceptional customer experience by connecting our customers with skillful and professional team members, provide innovative craftsmanship, designs, and products, and offer utmost personal care — one customer, one home, and one community at a time." />
          <meta property="og:title" content="Contact Us -Aspen Square Homes" />
          <meta property="og:type" content="aspensquarehomes" />
          <meta property="og:description" content="Our mission is to enrich our customers' lives with an exceptional customer experience by connecting our customers with skillful and professional team members, provide innovative craftsmanship, designs, and products, and offer utmost personal care — one customer, one home, and one community at a time." />
          <meta property="og:url" content="https://aspensquarehomes.com/contact-us" />
          <meta property="og:image" content={`${imageLink + '/company_settings/contact_us_cover_image_1/' + companyInfo.about_us_cover_image_1}`} />
        </Helmet>
        <Header />
        <div className="page-content bg-white">
          <PageTitle motherMenu="Contact Us" activeMenu="Contact Us " breadcrumbImage={imageLink + '/company_settings/contact_us_cover_image_1/' + companyInfo.about_us_cover_image_1} />
          {/*  Inner Page Banner */}
          <section className="content-inner-2" data-content="CONTACT US">
            <div className="container">
              <div className="section-head text-center">
                <span className="wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.2s">Contact us</span>
                <h2 className="title wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">Always Help You</h2>
              </div>
              <div className="banner-map wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                <iframe src={companyInfo.contact_us_map_link} style={{ border: "0", width: "100%", minHeight: "500px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3353.630248945856!2d-96.993403!3d32.802062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e83f1001ff70b%3A0x97cf96c462212470!2s920%20S%20Belt%20Line%20Rd%2C%20Irving%2C%20TX%2075060%2C%20USA!5e0!3m2!1sen!2sbd!4v1658834293540!5m2!1sen!2sbd" className="border-0 align-self-stretch rounded-sm" style={{border:"0", width:"100%", minHeight:"500px"}} allowfullscreen ></iframe> */}
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.3825624477!2d75.65046970649679!3d26.88544791796718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C+Rajasthan!5e0!3m2!1sen!2sin!4v1500819483219" 
									className="border-0 align-self-stretch rounded-sm" style={{border:"0", width:"100%", minHeight:"500px"}} allowfullscreen 
								/> */}
              </div>
            </div>
          </section>
          <section className="content-inner-2">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                  <div className="icon-bx-wraper m-sm-b30 left">
                    <div className="icon-md m-b20 m-t5">
                      <Link to={"#"} className="icon-cell text-primary">
                        <i className="las la-phone-volume"></i>
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h3 className="dlab-tilte m-b5">Phone</h3>
                      <p>{companyInfo.phone_1}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">
                  <div className="icon-bx-wraper m-sm-b30 left">
                    <div className="icon-md m-b20 m-t5">
                      <Link to={"#"} className="icon-cell text-primary">
                        <i className="las la-map"></i>
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h3 className="dlab-tilte m-b5">Address</h3>
                      <p>{companyInfo.address_1}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                  <div className="icon-bx-wraper left">
                    <div className="icon-md m-b20 m-t5">
                      <Link to={"#"} className="icon-cell text-primary">
                        <i className="las la-envelope-open"></i>
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h3 className="dlab-tilte m-b5">Email</h3>
                      <p>{companyInfo.email_1}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="content-inner contact-box">
            <div className="container">
              <div className="section-head text-center">
                <span className="wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">Get in touch with us</span>
                <h2 className="title wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">Contact us</h2>
              </div>
              <div className="dzFormMsg"></div>
              <form className="dzForm" onSubmit={this.onHandleSubmit}>
                {/* <input type="hidden" value="Contact" name="dzToDo" /> */}
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <div className="form-group">
                      <input name="name" id="name" type="text" required className="form-control" autoComplete="off" placeholder="Enter your name" autoCom onChange={(e) => this.autoSearchInputChange(e)} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <div className="form-group">
                      <input name="mail_from" type="email" id="mail_from" className="form-control" autoComplete="off" required placeholder="Your Email Address" onChange={(e) => this.autoSearchInputChange(e)} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <div className="form-group">
                      <input name="phone" type="text" id="phone" required className="form-control" autoComplete="off" placeholder="Phone" onChange={(e) => this.autoSearchInputChange(e)} />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <div className="form-group">
                      <textarea name="mail_body" id="mail_body" rows="4" className="form-control" autoComplete="off" required placeholder="Your message here" onChange={(e) => this.autoSearchInputChange(e)}></textarea>
                    </div>
                  </div>
                  {/* <div className="col-md-12 col-sm-12">
										<div className="form-group">
											<div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
											<input className="form-control d-none" style={{display:"none"}} data-recaptcha="true" required data-error="Please complete the Captcha" />
										</div>
									</div> */}
                  <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <div>
                      <div className="d-flex justify-content-center captcha-design ">
                        <LoadCanvasTemplate />
                      </div>
                      <div className="form-group">
                        <input placeholder="Enter Captcha"
                          id="user_captcha_input"
                          name="user_captcha_input"
                          type="text"
                          className="form-control"
                          onChange={(e) => this.autoSearchInputChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 text-center wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    <button type="submit" className="btn btn-primary btn-lg" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()} disabled={this.state.submit_btn == true}>Send Message</button>
                  </div>
                </div>
              </form>

              <Modal show={this.state.isOpen2} size="md" animation={true} centered>
                <Modal.Body>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-center">
                        <i className="fa fa-check text-light fa-2x icon-style"></i>
                      </div>
                      <div className="mt-3">
                        <h4 className="text-center text-uppercase text-uppercase">{this.state.success_message}</h4>
                        {/* <p className="text-center" style={{ fontSize: '10px' }}>Thank You ❤️. One of our responsible person contact with you asap!</p> */}
                      </div>
                      <div className="d-flex justify-content-center">
                        <button className="btn btn-primary" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()} onClick={this.closeModal2}>Ok</button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>

              </Modal>

              <Modal show={this.state.isOpen3} size="md" animation={true} centered>
                <Modal.Body>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-center">
                        <i className="fa fa-times text-light fa-2x icon-style2"></i>
                      </div>
                      <div className="mt-3">
                        <h4 className="text-center text-uppercase">{this.state.error_message}</h4>
                        {/* <p className="text-center" style={{ fontSize: '10px' }}>This lot is already booked!</p> */}
                      </div>
                      <div className="d-flex justify-content-center">
                        <button className="btn btn-primary" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()} onClick={this.closeModal3}>Ok</button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>

              </Modal>
            </div>
          </section>
          <div>
            <img src={imageLink + '/company_settings/contact_us_cover_image_2/' + companyInfo.about_us_cover_image_2} className="bg-view" alt="" />
          </div>
        </div>
        <Footer2 />
      </Fragment>
    )
  }
}
export default ContactUs2;