import react, { Component } from "react";
import Carousel from 'react-bootstrap/Carousel';
import { Form, Button, Dropdown, Modal, Table } from 'react-bootstrap';
import { inputButton, inputButtonHover, inputButtonPadding } from "../../../CommonStyle/CommonStyle";


class SliderSection extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const imageLink = process.env.REACT_APP_IMAGE_URL;
    const { city, companyInfo, homeSlider, isHover, handleMouseEnter, handleMouseLeave, autoSearchInputChange, onHandleSubmit, quick_move, cityInfo } = this.props;
    return (
      <div className="banner-three" data-content="HOME" id="sidenav_home">
        <Carousel fade>
          {
            homeSlider?.map((data, index) =>
              <Carousel.Item interval={companyInfo.slider_transection_time}>
                {
                  data.slider_large_image != null ?
                    <>
                      <img
                        className="d-block w-100 carousel-image"
                        src={imageLink + 'company_settings/dashboard/slider_large_image/' + data.slider_large_image}
                        alt={`${index} slider`}
                      />
                    </>
                    :
                    <img src="https://i0.wp.com/res.cloudinary.com/practicaldev/image/fetch/s--ExVB4I1A--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_66%2Cw_880/https://i0.wp.com/dev-to-uploads.s3.amazonaws.com/i/mrvsmk2pl3l8fwocbfhy.gif" className="d-block w-100 carousel-image" alt="placeholder" />
                }


              </Carousel.Item>
            )
          }
        </Carousel>

        <section className="section-1">
          <div className="row align-items-center m-0 p-0 banner-inner justify-content-center" >
            <div className="col-lg-4 col-md-3 col-sm-3">
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="col-md-12  wow fadeInDown" data-wow-duration="2s" data-wow-delay="0.2s">
                <div className="row ">
                  <div className="d-flex flex-column justify-content-center">
                    <img src={imageLink + 'company_settings/logo_small/' + companyInfo.logo_small} className="w-50 img-fluid mx-auto" alt="" />

                    <h6 className="logo-title-homepage text-center mb-0" style={{ color: '#fff' }}>Exceptional Excellence</h6><br></br>
                  </div>

                </div>
              </div>
              <div className="wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                <Form onSubmit={onHandleSubmit}>
                  <label className="font-weight-bold text-white">SELECT YOUR CITY</label>
                  <div className="input-group">
                    <select className="custom-select" id="inputGroupSelect04" name="cityInfo" value={cityInfo} onChange={(e) => autoSearchInputChange(e)} aria-label="Example select with button addon" >
                      <option value="">Any</option>
                      {
                        city && city.map((data, index) =>
                          <option value={data.id} > {data.city_name}</option>
                        )
                      }
                    </select>
                    <div className="input-group-append">
                      <button className="btn btn-sm btn-primary" type="submit" style={isHover == true ? (inputButtonHover('#569539', '#fff', '#fff'), inputButtonPadding()) : (inputButton('#000', '#569539', '#569539'), inputButtonPadding())} onMouseOver={() => handleMouseEnter} onMouseLeave={() => handleMouseLeave}>FIND YOUR HOME</button>
                    </div>
                  </div>
                  <div className="custom-control custom-checkbox  d-flex align-items-center">
                    <input type="checkbox" className="custom-control-input" id="quickMoveIn" name="quick_move" value={quick_move} onChange={(e) => autoSearchInputChange(e)} />
                    <label className="custom-control-label text-white" for="quickMoveIn">SHOW QUICK MOVE-IN ONLY</label>
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-lg-4 col-md-3 col-sm-3">
            </div>
          </div>
        </section>
      </div >
    )
  }
}

export default SliderSection;