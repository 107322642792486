import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import logo from './../../images/logo.png';
import AspenSquare_LOGOColor from '../../images/companyLogo/AspenSquare_LOGOColor.png';
import './Header.css';
import { btnHoverApplyStyle, btnApplyStyle, headerColor, iconColor, navBarBg } from '../CommonStyle/CommonStyle';
import { companyInfo } from '../../Api/Actions/companyInfo';
import { communitiesInfo } from '../../Api/Actions/coummnities';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      home: {
        data: '',
      },
      // typing: false,
      // typingTimeout: 0
      isHover: false,

    }
  }
  componentDidMount() {

    this.fetchCommunitiesInfo();
    this.fetchCompanyInfo();
    // sidebar open/close

    var Navicon = document.querySelector('.navicon');
    var sidebarmenu = document.querySelector('.myNavbar ');

    function toggleFunc() {
      sidebarmenu.classList.toggle('show');
      Navicon.classList.toggle('open');
    }
    Navicon.addEventListener('click', toggleFunc);


    // Sidenav li open close
    var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li'));
    var navUl2 = [].slice.call(document.querySelectorAll('.sub-menu > li > ul> li'));

    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener('click', function () { checkLi(this) });
    }






    function checkLi(current) {
      navUl.forEach(el => (current !== el) ? el.classList.remove('open') : '');
      // console.log('navUl', current.getElementsByClassName('sub-menu'));
      let childrenCount = current.children.length;
      // console.log(current.children.length)
      // console.log(current.children[1].classList.value);
      setTimeout(() => {

        if (current.classList == "") {
          current.classList.add('open');
          if (childrenCount > 1) {
            let currentValue = current.children[1];
            if (currentValue.children.length > 1) {
              for (let f = 0; f < currentValue.children.length; f++) {
                let currentChild = currentValue.children[f];
                var elemEventHandler = function () {
                  if (currentChild.children.length > 1) {
                    let currentChild2 = currentChild.children[1];
                    // console.log('currentChild2', currentChild2)
                    // currentChild2.classList.toggle('open');
                    for (let g = 0; g < currentChild2.children.length; g++) {
                      let currentChild3 = currentChild2.children[g];
                      // console.log('currentChild3', currentChild3)
                      // if (currentChild3.classList == "") {
                      currentChild3.classList.toggle('opened');
                      // } else {
                      // 	currentChild2.children[g].classList.remove('open')
                      // }
                    }
                  }
                };
                currentChild.addEventListener('click', elemEventHandler, false)

              }
              // currentChild.addEventListener('click', elemEventHandler, false)
              // console.log('currentChild', currentChild)
              // if(currentChild.length>1){
              // 	let currentChild2 = currentChild.children[1];
              // 	console.log("currentChild2,", currentChild2)
              // }
              // currentChild.classList.toggle('open');
            }

          }
        }
        else if (current.classList == 'open') {
          // current.classList = "";
          // console.log('hello');
          // console.log('true');
          // if(current.classList == 'open'){
          // 	current.classList.toggle('open')
          // }
          // current.parentNode.addEventListener('click', ()=>{
          // 	return false;
          // })
          // // navUl.forEach(el => (current !== el) ? el.classList.remove('open') : '');
          // current.classList.toggle('open');
          // current.classList.toggle('open');
          // console.log('current.parentNode.classList', current.parentNode.classList)
          // if()
          // current.parentNode.classList.toggle('open');
        }
        // current.classList.toggle('open');
        // current.classList.remove('open');
        // console.log('hello');
        // current.parentNode.classList.add('open');

        else {
          current.classList.toggle('open');
        }


        // //  alert(current.className.indexOf('open'));
        // 	if(current.className.indexOf('open') == -1){
        // 		current.classList.toggle('open');

        // 		// console.log('yes2');
        // 		// alert(2);
        // 	}else{
        // 	// alert(current.parentNode);
        // 		current.parentNode.classList.add('open');
        // 		// 	for (var x = 0; x < navUl2.length; x++) {
        // 		// 	navUl2[x].addEventListener('click', function () { 
        // 		// 		console.log('yes');
        // 		// 	 });
        // 		// }
        // 		console.log('noHi');
        // 	} 

      }, 100);

    }
  }

  // handleSubmit = (event) => {
  // 	const self = this;

  // 	if (self.state.typingTimeout) {
  // 	   clearTimeout(self.state.typingTimeout);
  // 	}


  // 	self.setState({
  // 	   data: event.target.value,
  // 	//    typing: false,
  // 	//    typingTimeout: setTimeout( () =>{
  // 	// 	  this.historyFunction(this.state.data)
  // 	// 	 }, 1000)
  // 	});
  // }

  autoSearchInputChange = (event) => {
    event.preventDefault();
    const { target: { name, value } } = event;
    this.updateHomeObject(name, value);
  }

  updateHomeObject = (name, value) => {
    let homeCopy = Object.assign({}, this.state.home);
    homeCopy[name] = value;
    this.setState({
      home: homeCopy
    });
  }

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { search } = this.state.home;
    const data = {
      search: search
    }
    window.location.href = `/searching/${data.search}`;
  }

  fetchCommunitiesInfo = () => {
    communitiesInfo().then(response => {
      this.setState({
        communitiesInfo: response.data,
      })
    })
  }

  fetchCompanyInfo = () => {
    companyInfo().then(response => {
      this.setState({
        companyInfo: response.data[0],
      })
    })
  }


  handleMouseEnter = () => {
    this.setState({
      isHover: true,
    })
  };
  handleMouseLeave = () => {
    this.setState({
      isHover: false,
    })
  };

  render() {
    const imageLink = process.env.REACT_APP_IMAGE_URL;

    let companyInfo = [];
    if (this.state.hasOwnProperty('companyInfo')) {
      companyInfo = this.state.companyInfo;
    }
    let communitiesInfo = [];
    if (this.state.hasOwnProperty('communitiesInfo')) {
      communitiesInfo = this.state.communitiesInfo;
    }


    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = imageLink + 'company_settings/favicon/' + companyInfo.favicon;

    return (
      <Fragment>
        {/*  header  */}
        <header className="site-header header-transparent">
          <HeaderContent
            autoSearchInputChange={this.autoSearchInputChange}
            onHandleSubmit={this.onHandleSubmit}
            isHover={this.state.isHover}
            handleMouseLeave={this.handleMouseLeave}
            handleMouseEnter={this.handleMouseEnter}
            companyInfo={companyInfo && companyInfo}
            imageLink={imageLink}
            communitiesInfo={communitiesInfo}
          />
        </header>
        {/*  header End  */}
      </Fragment>
    )
  }
}
function HeaderContent({ autoSearchInputChange, onHandleSubmit, isHover, handleMouseLeave, handleMouseEnter, companyInfo, imageLink, communitiesInfo }) {
  return (
    <>
      <div className="top-bar fixed-top" style={headerColor('#fff')}>
        <div className="container-fluid">
          <div className="row d-flex justify-content-md-between justify-content-center align-items-center">
            <div className="dlab-topbar-left">
              <ul>
                <li><i className="la la-phone-volume"></i> <span className="text-primary font-weight-bold"> {companyInfo.phone_1}</span></li>
                <li><i className="las la-envelope-open"></i> <span className="text-primary font-weight-bold"> {companyInfo.email_1}</span></li>
              </ul>
            </div>
            <div className="dlab-topbar-right ">
              <ul>
                <li>
                  <form onSubmit={(e, v) => onHandleSubmit(e, v)}>
                    <div class="input-group">

                      <input type="text" class="form-control border border-primary" name="search" onChange={(e) => autoSearchInputChange(e)} placeholder="Search ....." />
                      <div class="input-group-append ">
                        <button class="btn btn-sm btn-primary" type="submit" style={{ padding: "7px 30px" }}>
                          <i className="la la-search la-xs" style={{ color: 'white' }} ></i>
                        </button>
                      </div>

                    </div>
                  </form>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*   main header  */}
      <div className="fixed-top main-bar-wraper navbar-expand-lg mt-5 shadow-sm">
        <div className="main-bar clearfix div-wrapper" style={navBarBg('#000')}>
          <div className="container-fluid clearfix" >
            { /* website logo */}
            <div className="logo-header mostion logo-dark">
              <Link to={"/"} className="logo"><img src={imageLink + 'company_settings/logo_small/' + companyInfo.logo_small} className="img-fluid" alt="" style={{ width: '54%' }} /><h6 className="logo-title">Exceptional Excellence</h6></Link>
            </div>
            {/* nav toggle button  */}
            <button className="navbar-toggler collapsed navicon justify-content-end " type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span></span>
              <span></span>
              <span></span>
            </button>
            {/*extra nav  */}
            <div className="extra-nav" >
              <div className="extra-cell">
                <Link to={"/contact-us"} className="btn btn-light  btnhover13" style={isHover == true ? btnHoverApplyStyle('#569539', '#fff', '#fff') : btnApplyStyle('#fff', '#569539', '#569539')} onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>Apply Now</Link>
              </div>
            </div>
            <div className="header-nav navbar-collapse collapse myNavbar justify-content-end" id="navbarNavDropdown">
              <div className="logo-header d-md-block d-lg-none">
                {/* <Link to={"./"}><img src={logo} alt="" /></Link> */}
                <Link to={"/"}><img src={imageLink + 'company_settings/logo_small/' + companyInfo.logo_small} alt="" /><h6 className="logo-title-mobile">Exceptional Excellence</h6></Link>
              </div>
              <ul className="nav navbar-nav navbar">
                <li className="active"><Link to={"/"}>Home</Link>

                </li>
                <li className="active"><Link to={"/about-us"}>About</Link>
                </li>
                <li><Link to={"#"}>Communities<i className="fa fa-chevron-down"></i></Link>
                  <ul className="sub-menu" >
                    {communitiesInfo && communitiesInfo.map((community, index) =>
                      <>
                        {
                          community.status == 1 && community.is_active == 1 && <li key={index} ><Link to={community.subProject.length == 0 ? `/community/${community.project_slug}` : '#'} className="text-uppercase">{community.project_title}</Link>
                            {
                              community.subProject.length == 0 ?
                                '' :
                                <ul className="sub-menu ">
                                  {
                                    community.subProject && community.subProject.map((sub, index) =>
                                      sub.status == 1 && sub.is_active == 1 && <li><a href={`/communities/${sub.project_slug}`} key={index} className="text-uppercase">{sub.project_title}</a></li>
                                    )
                                  }
                                </ul>
                            }
                          </li>
                        }
                      </>
                    )}
                  </ul>
                </li>
                <li><Link to={"#"}>Helpful Tools<i className="fa fa-chevron-down"></i></Link>
                  <ul className="sub-menu">
                    <li><Link to="/gallery" className="text-uppercase">Gallery</Link></li>
                    <li>
                      <Link to="/articles" className="text-uppercase">Articles</Link>
                    </li>
                  </ul>
                </li>

                <li className="active"><Link to={"/our-investors"}>Investors</Link></li>

                <li className="active"><Link to={"/contact-us"}>Contact Us</Link>
                </li>
                <li className="search-link">
                  <div className="py-2">
                    <ul>
                      <li>
                        <form onSubmit={(e, v) => onHandleSubmit(e, v)}>
                          <div class="input-group">

                            <input type="text" class="form-control border border-primary" name="search" onChange={(e) => autoSearchInputChange(e)} placeholder="Search ....." />
                            <div class="input-group-append ">
                              <button class="btn btn-sm btn-primary" type="submit" style={{ padding: "7px 30px", background: '#569539' }}>
                                <i className="la la-search la-xs" style={{ color: 'white' }} ></i>
                              </button>
                            </div>

                          </div>
                        </form>
                      </li>

                    </ul>
                  </div>
                </li>
                <li className="search-link">
                  <Link to={"/contact-us"} className="btn btn-light text-primary btnhover13 my-2">Apply Now</Link>
                </li>
              </ul>
              <div className="dlab-social-icon">
                <ul>
                  <li><Link to={companyInfo && companyInfo.dashboard_youtube_link_2} target="_blank" className="text-white site-button circle fa fa-facebook" ></Link></li>
                  <li><Link to={companyInfo && companyInfo.dashboard_youtube_link_4} target="_blank" className="text-white site-button  circle fa fa-twitter" ></Link></li>
                  <li><Link to={companyInfo && companyInfo.dashboard_youtube_link_1} target="_blank" className="text-white site-button circle fa fa-youtube" ></Link></li>
                  <li><Link to={companyInfo && companyInfo.dashboard_youtube_link_3} target="_blank" className="text-white site-button circle fa fa-instagram"></Link></li>
                  <li><a href={"#"} target="_blank" className="text-white fa fa-pinterest site-button circle fa fa-instagram"></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export { HeaderContent };
export default Header;